import './App.css';

function App() {
  return (
    <div className="App">
      <span><h1>Griffin Tennent - Software Engineer</h1></span>
      <span><h4>Portfolio Coming Soon...</h4></span>
      <a href="https://markdown.griffintennent.com">Markdown Previewer</a>
    </div>
  );
}

export default App;
